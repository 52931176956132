import React from 'react'

export default function TopTokens() {
  return (
    <div>
      
        <h5 className="text-[#1B4AE5] text-xl text-center font-semibold pt-5 pb-8">
          Top Token
        </h5>
        <div className="w-full flex  gap-20 justify-between items-center text-base border-b-[1px] border-gray-600 py-3 px-5">
          <div className="flex  gap-20 items-center">
            <div>
              <h5>RANK</h5>
            </div>
            <div className="flex items-center gap-2">
              <h5>TOKEN</h5>
            </div>
          </div>
          <div>
            <h5>24H VOLUME</h5>
          </div>
        </div>
        <div className="w-full flex  gap-20 justify-between items-center py-3 border-b-[1px] border-gray-600 px-5">
          <div className="flex  gap-20 items-center">
            <div>
              <h5>No.1</h5>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-[35px] h-[35px] rounded-full bg-black"></div>
              <h5>Bank</h5>
            </div>
          </div>
          <div>
            <h5>$48,294,211.7987</h5>
          </div>
        </div>
        <div className="w-full flex  gap-20 justify-between items-center py-3 border-b-[1px] border-gray-600 px-5">
          <div className="flex  gap-20 items-center">
            <div>
              <h5>No.1</h5>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-[35px] h-[35px] rounded-full bg-black"></div>
              <h5>Bank</h5>
            </div>
          </div>
          <div>
            <h5>$48,294,211.7987</h5>
          </div>
        </div>
        <div className="w-full flex  gap-20 justify-between items-center py-3 border-b-[1px] border-gray-600 px-5">
          <div className="flex  gap-20 items-center">
            <div>
              <h5>No.1</h5>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-[35px] h-[35px] rounded-full bg-black"></div>
              <h5>Bank</h5>
            </div>
          </div>
          <div>
            <h5>$48,294,211.7987</h5>
          </div>
        </div>
        <div className="w-full flex  gap-20 justify-between items-center py-3 border-b-[1px] border-gray-600 px-5">
          <div className="flex  gap-20 items-center">
            <div>
              <h5>No.1</h5>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-[35px] h-[35px] rounded-full bg-black"></div>
              <h5>Bank</h5>
            </div>
          </div>
          <div>
            <h5>$48,294,211.7987</h5>
          </div>
        </div>
        <div className="w-full flex  gap-20 justify-between items-center py-3 border-b-[1px] border-gray-600 px-5">
          <div className="flex  gap-20 items-center">
            <div>
              <h5>No.1</h5>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-[35px] h-[35px] rounded-full bg-black"></div>
              <h5>Bank</h5>
            </div>
          </div>
          <div>
            <h5>$48,294,211.7987</h5>
          </div>
        </div>
        <div className="w-full flex  gap-20 justify-between items-center py-3 border-b-[1px] border-gray-600 px-5">
          <div className="flex  gap-20 items-center">
            <div>
              <h5>No.1</h5>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-[35px] h-[35px] rounded-full bg-black"></div>
              <h5>Bank</h5>
            </div>
          </div>
          <div>
            <h5>$48,294,211.7987</h5>
          </div>
        </div>
        <div className="w-full flex  gap-20 justify-between items-center py-3 border-b-[1px] border-gray-600 px-5">
          <div className="flex  gap-20 items-center">
            <div>
              <h5>No.1</h5>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-[35px] h-[35px] rounded-full bg-black"></div>
              <h5>Bank</h5>
            </div>
          </div>
          <div>
            <h5>$48,294,211.7987</h5>
          </div>
        </div>
        <div className="w-full flex  gap-20 justify-between items-center py-3 border-b-[1px] border-gray-600 px-5">
          <div className="flex  gap-20 items-center">
            <div>
              <h5>No.1</h5>
            </div>
            <div className="flex items-center gap-2">
              <div className="w-[35px] h-[35px] rounded-full bg-black"></div>
              <h5>Bank</h5>
            </div>
          </div>
          <div>
            <h5>$48,294,211.7987</h5>
          </div>
        </div>
      </div>
   
  );
}
