export const links = [
  {
    name: "MENU",
    submenu: true,
    sublinks: [
      {
        Head: "Service",
        sublink: [
          { name: "Testing", link: "/" },
          { name: "Testing", link: "/" },
          { name: "Testing", link: "/" },
          { name: "Testing", link: "/" },
          { name: "Testing", link: "/" },
        ],
      },

      {
        Head: "Services",
        sublink: [
          { name: "Testing", link: "/" },
          { name: "Testing", link: "/" },
          { name: "Testing", link: "/" },
          { name: "Testing", link: "/" },
          { name: "Testing", link: "/" },
        ],
      },

      {
        Head: "Top Community",
        sublink: [
          { name: "Testing", link: "/" },
          { name: "Testing", link: "/" },
          { name: "Testing", link: "/" },
          { name: "Testing", link: "/" },
          { name: "Testing", link: "/" },
        ],
      },
    ],
  },
];
